<template>
    <div class="grid">
        <div class="col-12">
            <div class="card p-2 mb-2">
                <Toolbar class="p-0" style="background-color: white; border: none;">
                    <template #start>
                        <Button icon="pi pi-user" severity="info" rounded outlined aria-label="User" />
                        <p><b class="ml-2">Ficha de Atención: </b> {{ oFicha.NombrePaciente }} - {{ oFicha.NumeroDocumento }}</p>
                    </template>

                    <template #end>
                        <Button label="Atrás" icon="pi pi-arrow-left" severity="secondary" @click="IrAtras"></Button>
                    </template>
                </Toolbar>
            </div>

            <div class="card">
                <div v-if="bCargando" class="progress-spinner-custom">
                    <div class="spinner">
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    </div>
                </div>
                <Toast />
                <ConfirmDialog></ConfirmDialog>
                <div class="flex flex-column h-500rem">
                    <h5>{{ oPago.CodigoPago != undefined && oPago.CodigoPago != '' ? 'Pago #' + oPago.CodigoPago : 'Nuevo Pago' }}</h5>
                    <div class="border-2 border-dashed surface-border border-round surface-ground justify-content-center align-items-center font-medium">
                        <div class="p-fluid p-formgrid grid m-5 mb-0 mt-0">
                            <div class="field col-12 md:col-9 pb-0 mb-0">
                                <p><b class="ml-2">Paquete: </b> {{ oFicha.NombreProducto }} <Tag severity="info">{{ oFicha.NombreCategoria }}</Tag></p>
                            </div>
                            <div class="field col-12 md:col-3 text-right font-bold pb-0 mb-0">
                                <p>Atención #: {{ oFicha.CodigoFicha }}</p>
                            </div>

                            <Divider layout="horizontal" class="pt-1 mt-1" />

                            <div class="field col-12 md:col-9 mb-2 pb- pt-0">
                                <p><b class="ml-2">Datos de Pagador</b></p>
                            </div>
                            <div class="field col-12 md:col-3 text-right">
                                <label for="lblEstado" class="mr-2 label-check"> El Pagador es el Paciente</label>
                                <Checkbox id="chkMarcarPagador" v-model="pagadorEsPaciente" :binary="true" @change="MarcarPagador()" />
                            </div>
                            <div class="field col-12 md:col-3">
                                <FloatLabel>
                                    <Dropdown v-model="oPago.TipoDocumento" :options="oListaTipoDocumento" 
                                        optionLabel="Nombre" optionValue="TipoDocumentoId" 
                                        placeholder="Tipo Documento" class="w-full" 
                                        :class="{ 'p-invalid' : tipoDocumentoInvalido }" @change="SeleccionarTipoDocumento()" :disabled="pagadorEsPaciente" />
                                    <label for="lblTipoDocumento">Tipo Documento</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-3">
                                <FloatLabel>
                                    <InputText v-model="oPago.NumeroDocumento" autocomplete="off" :maxlength="ObtenerMaximoCaracteres()"
                                    :class="{ 'p-invalid' : numeroDocumentoInvalido }" :disabled="pagadorEsPaciente" />
                                    <label for="lblNumeroDocumento">Número Documento</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-6">
                                <FloatLabel>
                                    <InputText v-model="oPago.NombrePagador" type="text" 
                                    :class="{ 'p-invalid' : nombrePagadorInvalido }" :disabled="pagadorEsPaciente" />
                                    <label for="lblNombrePagador">Nombre Pagador</label>
                                </FloatLabel>
                            </div>                           

                            <Divider layout="horizontal" class="pt-1 mt-0 mb-5" />

                            <div class="field col-12 md:col-3">
                                <FloatLabel>
                                    <Calendar showIcon iconDisplay="input" v-model="oPago.FechaPago"
                                        inputId="dtpFechaPago" dateFormat="dd/mm/yy" showTime hourFormat="12"
                                        :class="{ 'p-invalid' : fechaPagoInvalido }" :disabled="!oPago.Editable" />
                                    <label for="lblFechaPago">Fecha Pago</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-3">
                                <FloatLabel>
                                    <InputNumber autocomplete="off" v-model="oPago.Importe" 
                                        inputId="locale-us" locale="en-ES" :minFractionDigits="2" :disabled="!oPago.Editable"
                                        :class="{ 'p-invalid' : importeInvalido }" @input="CalcularImportePendiente" />
                                    <label for="lblImporte">Importe a Pagar</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-3">
                                <FloatLabel>
                                    <InputNumber autocomplete="off" v-model="oFicha.ImportePagado" 
                                        inputId="locale-us" locale="en-ES" :minFractionDigits="2" disabled />                                    
                                    <label for="lblImporte">Importe Pagado</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-3">
                                <FloatLabel>
                                    <InputNumber autocomplete="off" v-model="importePendiente" 
                                        inputId="locale-us" locale="en-ES" :minFractionDigits="2" 
                                        disabled class="inputtext-importe-pendiente" />
                                    <label for="lblImporte">Pendiente a Pagar</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-3">
                                <FloatLabel>
                                    <Dropdown v-model="oPago.MedioPagoId" :options="oListaMediosPago"
                                        optionLabel="Nombre" optionValue="MedioPagoId" class="w-full" :disabled="!oPago.Editable"
                                        :class="{ 'p-invalid' : medioPagoInvalido }" @change="SeleccionarMedioPago()" />
                                    <label for="lblMedioPago">Medio de Pago</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-3">
                                <FloatLabel v-if="requiereCodigoOperacion">
                                    <InputText v-model="oPago.CodigoOperacion" type="text" :disabled="!oPago.Editable"
                                    :class="{ 'p-invalid' : codigoOperacionInvalido }" />
                                    <label for="lblCodigoOperacion">Código Operación</label>
                                </FloatLabel>
                            </div>   
                            <div class="field col-12 md:col-6">
                                <FloatLabel v-if="requiereDetalleOperacion">
                                    <InputText v-model="oPago.DetalleOperacion" type="text" :disabled="!oPago.Editable"
                                    :class="{ 'p-invalid' : detalleOperacionInvalido }" />
                                    <label for="lblDetalleOperacion">Detalle Operación</label>
                                </FloatLabel>
                            </div>  
                            <div class="field col-12 md:col-3 mb-0">
                                <FloatLabel>
                                    <Dropdown v-model="oPago.PersonalId" :options="oListaPersonalComision"
                                        optionLabel="NombreCompleto" optionValue="PersonalId" class="w-full"  
                                        :class="{ 'p-invalid' : requiereComisiona }" />
                                    <label for="lblFisioterapeuta">Comisiona</label>
                                </FloatLabel>
                            </div> 
                            <div class="field col-12 md:col-12 mt-3">
                                <FloatLabel>
                                    <Textarea id="txtReferencia" v-model="oPago.Observacion" rows="3" cols="30" 
                                    :class="{ 'p-invalid' : observacionInvalido }" />
                                    <label for="lblObservacion">Observación</label>
                                </FloatLabel>
                            </div>

                        </div>
                    </div>

                    <div class="flex mt-4 justify-content-end">
                        <Button label="Registrar Pago" icon="pi pi-save" iconPos="right" @click="RegistrarPago()" :loading="bCargando" />
                    </div>

                </div>
            </div>

        </div>
    </div>

    <ConfirmDialog group="headless">
        <template #container="{ message, acceptCallback }">
            <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
                <div class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
                    <i class="pi pi-check text-5xl"></i>
                </div>
                <span class="font-bold text-2xl block mb-2 mt-4">{{ message.header }}</span>
                <p class="mb-0">{{ message.message }}</p>
                <div class="flex align-items-center gap-2 mt-4">
                    <Button label="Aceptar" @click="acceptCallback"></Button>
                </div>
            </div>
        </template>
    </ConfirmDialog>

    
    <ConfirmDialog group="headlessvalida">
        <template #container="{ message, acceptCallback }">
            <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
                <div class="border-circle custom-bg-warning inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
                    <i class="pi pi-exclamation-triangle text-5xl"></i>
                </div>
                <span class="font-bold text-2xl block mb-2 mt-4">{{ message.header }}</span>
                <p class="mb-0">{{ message.message }}</p>
                <div class="flex align-items-center gap-2 mt-4">
                    <Button label="Aceptar" class="custom-bg-warning" @click="acceptCallback"></Button>
                </div>
            </div>
        </template>
    </ConfirmDialog>

</template>

<script setup>
import ConstanteService from '@/service/ConstanteService';
import FichaAtencionService from '@/service/FichaAtencion';
import MedioPagoService from '@/service/MedioPagoService';
import PagoAtencionService from '@/service/PagoAtencion';
import PersonalService from '@/service/PersonalService';
import Crypto from '@/utilitarios/Crypto';
import Utilitario from '@/utilitarios/Utilitario';
import { usePrimeVue } from 'primevue/config';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

//#region Variables
const route = useRoute();
const store = useStore();
const router = useRouter();
const toast = useToast();
const confirm = useConfirm();

const fichaAtencionService = new FichaAtencionService();
const constanteService = new ConstanteService();
const medioPagoService = new MedioPagoService();
const pagoAtencionService = new PagoAtencionService();
const personalService = new PersonalService();

let usuarioStore = JSON.parse(store.state.datauser);
const bCargando = ref(false);
const fichaId = ref('');
const pagoId = ref('');
const idPaciente = ref(0);
const idProducto = ref(0);
const importePendiente = ref(0);
const oListaTipoDocumento = ref([]);
const oListaMediosPago = ref([]);
const oListaPersonalComision = ref([]);
const pagadorEsPaciente = ref(false);
const tipoDocumentoInvalido  = ref(false);
const numeroDocumentoInvalido = ref(false);
const nombrePagadorInvalido = ref(false);
const fechaPagoInvalido = ref(false);
const importeInvalido = ref(false);
const medioPagoInvalido = ref(false);
const codigoOperacionInvalido = ref(false);
const detalleOperacionInvalido = ref(false);
const observacionInvalido = ref(false);
const requiereCodigoOperacion = ref(false);
const requiereDetalleOperacion = ref(false);
const requiereComisiona = ref(false);
const oFicha = ref({});
const oPago = ref({
    PagoAtencionId: 0,
    FechaPago: new Date(),
    Importe: 0,
    MedioPagoId: 1
});

//#endregion

//#region Eventos
onMounted(() => {
     inicializador();
     CambiarEspanol();
});
//#endregion

//#region Metodos
const inicializador = async() => {
    bCargando.value = true;
    usuarioStore = JSON.parse(store.state.datauser);
    await ValidarCaja();
    fichaId.value = Crypto.Desencriptar(route.params.fichaid);
    await ObtenerFicha(fichaId.value);
    await ListarTipoDocumento();
    await ListarMedioPago();
    await ListarPersonal();
    pagoId.value = Crypto.Desencriptar(route.params.pagoid);
    if(!Utilitario.StringIsNullOrEmpty(pagoId.value)) {
        await ObtenerPago();
    }
    bCargando.value = false;
}

const CambiarEspanol = () => {
    const primevue = usePrimeVue();
    primevue.config.locale.dayNames = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
    primevue.config.locale.dayNamesShort = ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"];
    primevue.config.locale.dayNamesMin = ["D", "L", "M", "X", "J", "V", "S"];
    primevue.config.locale.firstDayOfWeek = 1;
    primevue.config.locale.monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    primevue.config.locale.monthNamesShort = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    primevue.config.locale.today = "Hoy";
    primevue.config.locale.clear = "Limpiar";
    primevue.config.locale.dateFormat = "dd/mm/yy";
    primevue.config.locale.weekHeader = "Sem";
    primevue.config.locale.pending = "Pendiente";
}

const IrAtras = () => {
    router.push({ path: `/portal/fichaatencion/${encodeURIComponent(Crypto.Encriptar(idPaciente.value))}/${encodeURIComponent(Crypto.Encriptar(idProducto.value))}/${encodeURIComponent(route.params.fichaid)}` });
}

const ObtenerMaximoCaracteres = () => {
    if (oPago.value.TipoDocumento) {
        const tipoDocumento = oListaTipoDocumento.value.find(doc => doc.TipoDocumentoId === oPago.value.TipoDocumento);
        return tipoDocumento ? tipoDocumento.NumeroCaracteres : 0;
    } else {
        return 0;
    }
}

const ListarTipoDocumento = async() => {
    constanteService.ListarTipoDocumentosService().then(
        response => {
            oListaTipoDocumento.value = response;
        }
    );
}

const ListarMedioPago = async() => {
    const response = await medioPagoService.ListarMedioPagoService().then();
    if(response.Status == 200) {
        oListaMediosPago.value = response.Data;
    }
}

const ObtenerFicha = async(idParametro) => {
    try{
        const request = {
            Id: idParametro
        };
        
        const response = await fichaAtencionService.ObtenerFichaService(request).then();
        if(response.Status == 200){
            const obtenerFicha = response.Data;
            oFicha.value.FichaAtencionId = obtenerFicha.FichaAtencionId;
            oFicha.value.CodigoFicha = obtenerFicha.CodigoFichaAtencion;
            oFicha.value.NombrePaciente = obtenerFicha.NombrePaciente;
            oFicha.value.TipoDocumentoId = obtenerFicha.TipoDocumentoId;
            oFicha.value.NumeroDocumento = obtenerFicha.NumeroDocumento;
            oFicha.value.NombreProducto = obtenerFicha.NombreProducto;
            oFicha.value.CategoriaId = obtenerFicha.CategoriaId;
            oFicha.value.NombreCategoria = obtenerFicha.NombreCategoria;
            oFicha.value.PrecioFinal = obtenerFicha.PrecioFinal;
            oFicha.value.ImportePagado = obtenerFicha.ImportePagado;
            oFicha.value.PersonaId = obtenerFicha.PersonaId;
            importePendiente.value = (obtenerFicha.PrecioFinal - obtenerFicha.ImportePagado);

            idPaciente.value = obtenerFicha.PacienteId;
            idProducto.value = obtenerFicha.ProductoId;
        }
    } catch (error) {
        console.error('Error al ObtenerFicha:', error);
    }
}

const ObtenerPago = async() => {
    try{
        const request = {
            PagoAtencionId: pagoId.value
        };
        
        const response = await pagoAtencionService.ObtenerPagoAtencionService(request).then();
        if(response.Status == 200){
            const obtenerPago = response.Data;
            oPago.value.PagoAtencionId = obtenerPago.PagoAtencionId;
            oPago.value.CodigoPago = obtenerPago.CodigoPagoAtencion;
            oPago.value.TipoDocumento = String(obtenerPago.TipoDocumentoId);
            oPago.value.NumeroDocumento = obtenerPago.NumeroDocumento;
            oPago.value.NombrePagador = obtenerPago.NombrePagador;
            oPago.value.PersonaId = obtenerPago.PersonaId;
            oPago.value.PersonalId = obtenerPago.PersonalComisionId;
            if(!Utilitario.StringIsNullOrEmpty(obtenerPago.PersonaId)) {
                pagadorEsPaciente.value = true;
            }
            else {
                pagadorEsPaciente.value = false;
            }
            oPago.value.FechaPago = new Date(obtenerPago.FechaPago);
            oPago.value.Importe = obtenerPago.Importe;
            oPago.value.MedioPagoId = obtenerPago.MedioPagoId;
            await SeleccionarMedioPago();
            oPago.value.CodigoOperacion = obtenerPago.CodigoOperacion;
            oPago.value.DetalleOperacion = obtenerPago.DetalleOperacion;
            oPago.value.Observacion = obtenerPago.Observacion;
            oPago.value.Editable = obtenerPago.Editable;
            
            oFicha.value.ImportePagado = parseFloat(oFicha.value.ImportePagado) - parseFloat(oPago.value.Importe);
            importePendiente.value = (oFicha.value.PrecioFinal - (oFicha.value.ImportePagado + oPago.value.Importe));
        }
    } catch (error) {
        console.error('Error al ObtenerFicha:', error);
    }
}

const RegistrarPago = async() => {
    try{
        const estadoValidacion = ref(false);
        const { ...model } = oPago.value;
        
        ValidarCaja();

        if(!model?.TipoDocumento){
            tipoDocumentoInvalido.value = true;
            estadoValidacion.value = true;
        }
        else {
            tipoDocumentoInvalido.value = false;
        }

        if(!model?.NumeroDocumento){
            numeroDocumentoInvalido.value = true;
            estadoValidacion.value = true;
        }
        else {
            numeroDocumentoInvalido.value = false;
        }

        if(!model?.NombrePagador){
            nombrePagadorInvalido.value = true;
            estadoValidacion.value = true;
        }
        else {
            nombrePagadorInvalido.value = false;
        }

        if(!model?.FechaPago){
            fechaPagoInvalido.value = true;
            estadoValidacion.value = true;
        }
        else {
            fechaPagoInvalido.value = false;
        }

        if(!model?.Importe){
            importeInvalido.value = true;
            estadoValidacion.value = true;
        }
        else {
            importeInvalido.value = false;
        }

        if(!model?.MedioPagoId){
            medioPagoInvalido.value = true;
            estadoValidacion.value = true;
        }
        else {
            medioPagoInvalido.value = false;
            if(requiereCodigoOperacion.value) {
                if(!model?.CodigoOperacion){
                    codigoOperacionInvalido.value = true;
                    estadoValidacion.value = true;
                }
                else {
                    codigoOperacionInvalido.value = false;
                }
            }

            if(requiereDetalleOperacion.value) {
                if(!model?.DetalleOperacion){
                    detalleOperacionInvalido.value = true;
                    estadoValidacion.value = true;
                }
                else {
                    detalleOperacionInvalido.value = false;
                }
            }
        }
        
        if(!model?.PersonalId){
            requiereComisiona.value = true;
            estadoValidacion.value = true;
        }
        else {
            requiereComisiona.value = false;
        }

        if(estadoValidacion.value){
            toast.add({ severity: 'warn', summary: 'Validación', detail: 'Por favor complete todos los campos requeridos.', life: 3000 });
            return;
        }

        const request = {
            ...model,
            ...oFicha.value,
            PersonaId: (pagadorEsPaciente.value ? oFicha.value.PersonaId : 0),
            CajaId: usuarioStore.Caja.CajaId,
            PersonalComisionId: oPago.value.PersonalId,
            UsuarioCreacion: usuarioStore.User.UsuarioId
        };  

        confirm.require({
            message: `¿Está seguro de registrar el pago de S/. ${Utilitario.formatearDecimal(oPago.value.Importe)}?`,
            header: 'Confirmación',
            icon: 'pi pi-info-circle',
            rejectLabel: 'No',
            acceptLabel: 'Si',
            accept: async () => {
                bCargando.value = true;
                const response = await pagoAtencionService.RegistrarPagoAtencionService(request).then();
                if(response.Status == 201){
                    const codigoPago = response.Data[0].CodigoPago;

                    confirm.require({
                        group: 'headless',
                        header: 'Felicidades',
                        message: `Se registró el pago #${codigoPago} correctamente, por el importe de S/.${Utilitario.formatearDecimal(oPago.value.Importe)}`,
                        accept: async() => {
                            bCargando.value = false;
                            router.push({ path: `/portal/fichaatencion/${encodeURIComponent(Crypto.Encriptar(idPaciente.value))}/${encodeURIComponent(Crypto.Encriptar(idProducto.value))}/${encodeURIComponent(Crypto.Encriptar(fichaId.value))}` });
                        }
                    });
                }
            }
        });
    } catch (error) {
        console.error('Error al ObtenerFicha:', error);
    }
}

const MarcarPagador = async() => {
    try{
        if(pagadorEsPaciente.value) {
            oPago.value.TipoDocumento = String(oFicha.value.TipoDocumentoId);
            oPago.value.NumeroDocumento = oFicha.value.NumeroDocumento;
            oPago.value.NombrePagador = oFicha.value.NombrePaciente;
        }
        else {
            oPago.value.TipoDocumento = '';
            oPago.value.NumeroDocumento = '';
            oPago.value.NombrePagador = '';
        }
    } catch (error) {
        console.error('Error al MarcarPagador:', error);
    }
}

const SeleccionarMedioPago = async() => {
    try {
        requiereCodigoOperacion.value = false;
        requiereDetalleOperacion.value = false;
        oPago.value.CodigoOperacion = '';
        oPago.value.DetalleOperacion = '';

        if(oPago.value.MedioPagoId) {
            const oMedioPago = oListaMediosPago.value.find(x => x.MedioPagoId == oPago.value.MedioPagoId);
            if(oMedioPago) {
                requiereCodigoOperacion.value = oMedioPago.RequiereCodigoOperacion;
                requiereDetalleOperacion.value = oMedioPago.RequiereDetalleOperacion;
            }
        }
    } catch (error) {
        console.error('Error al SeleccionarMedioPago:', error);
    }
}

const SeleccionarTipoDocumento = async() => {
    try{
        oPago.value.NumeroDocumento = '';
    } catch (error) {
        console.error('Error al SeleccionarTipoDocumento:', error);
    }
}

const CalcularImportePendiente = async(importePago) => {
    try{
        const valorActual = (oFicha.value.PrecioFinal - (oFicha.value.ImportePagado + importePago.value));
        importeInvalido.value = false;
        console.log(valorActual);
        
        if(valorActual < 0){
            toast.add({ severity: 'error', summary: 'Validación', detail: 'El importe a pagar no puede ser mayor que el importe pendiente a pagar.', life: 6000 });
            oPago.value.Importe = 0;
            importePendiente.value = (oFicha.value.PrecioFinal - oFicha.value.ImportePagado);
            importeInvalido.value = true;

            return;
        }

        importePendiente.value = valorActual;
        oPago.value.Importe = importePago.value;
    } catch (error) {
        console.error('Error al CalcularImportePendiente:', error);
    }
}

const ValidarCaja = async() => {
    if(!usuarioStore.Caja) {
        confirm.require({
            group: 'headlessvalida',
            header: 'CAJA',
            message: `No puede registrar pagos porque no tiene aperturada la caja.`,
            accept: async() => {
                bCargando.value = false;
                IrAtras();
            }
        });
    }
    else{
        if(usuarioStore.Caja.DiaDiferente) {
            confirm.require({
                group: 'headlessvalida',
                header: 'CAJA',
                message: `Tiene aperturada la caja del día ${Utilitario.formatoFecha(usuarioStore.Caja.Fecha, 'dd/MM/yyyy')}. \nPor favor cerrar y abrir en la fecha actual.`,
                accept: async() => {
                    IrAtras();
                }
            });
        }
    }
}

const ListarPersonal = async() => {
    try{
        const request = {
            TipoPersonalId : 0
        };
        
        const response = await personalService.ListarPersonalService(request).then();
        if(response.Status == 200){
            oListaPersonalComision.value = response.Data.filter(x => x.Comisiona).map(fila => ({ ...fila, NombreCompleto : fila.Persona.NombreCompleto }));
        }
    }
    catch (error) {
        console.error('Error al ListarPersonal:', error);
    }
}
//#endregion
</script>

<style scoped lang="scss">
.label-check{
    margin-top: 7px;
    margin-bottom: 0px;
}
</style>