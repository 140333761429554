<template>
    <div class="grid">
        <div class="col-12">
            <div class="card p-2 mb-2">
                <Toolbar class="p-0" style="background-color: white; border: none;">
                    <template #start>
                        <Button icon="pi pi-user" severity="info" rounded outlined aria-label="User" />
                        <Skeleton width="200px" height="1.5rem" class="ml-2" />
                    </template>

                    <template #end>
                        <Button label="Atrás" icon="pi pi-arrow-left" severity="secondary"></Button>
                    </template>
                </Toolbar>
            </div>
            
            <div class="card pb-3">
                <div class="flex flex-column h-500rem">
                    <div class="border-2 border-dashed surface-border border-round surface-ground justify-content-center align-items-center font-medium">
                        <div class="p-fluid p-formgrid grid m-5 mb-0 mt-0">
                            <div class="field col-12 md:col-9 pb-0 mb-0">
                                <Skeleton width="200px" height="1.5rem" class="ml-2" />
                            </div>
                            <div class="field col-12 md:col-3 text-right font-bold pb-0 mb-0">                                
                                <Skeleton width="200px" height="1.5rem" class="ml-2" />
                            </div>

                            <Divider layout="horizontal" class="pt-1 mt-1 mb-5" />

                            <div class="field col-12 md:col-4">
                                <Skeleton width="100%" height="2rem" />
                            </div>
                            <div class="field col-12 md:col-4">
                                <Skeleton width="100%" height="2rem" />
                            </div>
                            <div class="field col-12 md:col-4">
                                <Skeleton width="100%" height="2rem" />
                            </div>
                            <div class="field col-12 md:col-4">
                                <Skeleton width="100%" height="2rem" />
                            </div>
                            <div class="field col-12 md:col-2">
                                <Skeleton width="100%" height="2rem" />
                            </div>
                            <div class="field col-12 md:col-2">
                                <Skeleton width="100%" height="2rem" />
                            </div>
                            <div class="field col-12 md:col-4">
                                <Skeleton width="100%" height="2rem" />
                            </div>
                            <div class="field col-12 md:col-12">
                                <Skeleton width="100%" height="4rem" />
                            </div>
                        </div>
                    </div>

                    <div class="flex mt-3 justify-content-end">
                        <div class="field col-12 md:col-10"></div>
                        <div class="field col-12 md:col-2">
                            <Skeleton width="100%" height="2rem" />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
